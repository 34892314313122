(() => {
  if ('maxTouchPoints' in window.navigator && (window.navigator.maxTouchPoints > 0)) {
    // タップ対応デバイスに touch-deviceクラスを付与
    document.body.classList.add('touch-device');
  }

  // https://zenn.dev/tak_dcxi/articles/2ac77656aa94c2cd40bf
  const setFillHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  window.addEventListener('resize', setFillHeight);
  setFillHeight();
})();

/**
 *
 * @param {HTMLElement} elm
 * @returns {string}
 */
function getLoadImagePath (elm) {
  const path = elm.getAttribute('data-background');
  return path || '';
}

/**
 *
 * @param {HTMLElement} image
 * @returns {Promise<boid>}
 */
function getLoadPromise (image) {
  if (image.complete) {
    return Promise.resolve(); // イレギュラーケースだけど一応
  }
  return new Promise((resolve, reject) => {
    image.addEventListener('load', resolve, false);
    image.addEventListener('error', reject, false);
  });
}

window.addEventListener('load', () => {
  const targetElms = document.querySelectorAll('.js-lazyload');
  const promises = [];

  for (let i = 0, len = targetElms.length; i < len; ++i) {
    const image = new Image();
    const path = getLoadImagePath(targetElms[i]);

    image.src = path;

    const promise = getLoadPromise(image).then(() => {
      // 画像のロードが完了したら、パスの差し替え
      targetElms[i].style.backgroundImage = `url('${path}')`;
    });

    promises.push(promise);
  }

  Promise.all(promises).finally(() => {
    for (let i = 0, len = targetElms.length; i < len; ++i) {
      // エラー有無にかかわらず is-doneを付与
      targetElms[i].classList.add('is-done');
    }
  });
}, false);
